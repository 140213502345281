import * as React from "react";
import { graphql } from "gatsby";
import { Link as GatsbyLink } from "gatsby";
import Layout from "../components/Layout";
import { Flex, Text, Heading } from "@chakra-ui/react";
import slugify from "slugify";

const Videos = ({ location, data }) => {
  let records = data.allAirtable.edges.map((record) => record.node.data);
  const items = records.filter(
    (record) =>
      record.headline && record.link && record.category !== "DUMMY_RECORD"
  );

  return (
    <Layout bgColor="white" path={location.pathname}>
      <Flex
        direction="column"
        py="30px"
        maxW="800px"
        minH="65vh"
        fontFamily="Work Sans"
        margin="auto"
        px="30px"
      >
        <Heading
          w="100%"
          color="green.500"
          as="h1"
          mb="4"
          fontFamily="Montserrat"
        >
          Past Videos
        </Heading>
        {items.map((item) => {
          const slug = slugify(item.headline, {
            lower: true,
            strict: true,
          });
          const url = `/${item.category}/${slug}`;
          return (
            <Flex direction="column" mb="6">
              <Text
                fontWeight="600"
                variant="faqText"
                fontSize={{ base: "12px", md: "14px", lg: "15px" }}
                fontFamily="Montserrat"
                color="blue"
                as={GatsbyLink}
                to={url}
                mb="1"
              >
                {item.headline}
              </Text>

              <Text fontSize={{ base: "10px", md: "10px", lg: "12px" }}>
                Source: {item.source}
              </Text>
            </Flex>
          );
        })}
      </Flex>
    </Layout>
  );
};

export const query = graphql`
  query Videosuery {
    allAirtable(
      filter: { table: { eq: "videos" } }
      sort: { fields: data___autonumber, order: DESC }
    ) {
      edges {
        node {
          data {
            headline
            category
            link
            source
          }
        }
      }
    }
  }
`;

export default Videos;
